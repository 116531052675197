@charset "utf-8";

*{font-family: 'Noto Sans CJK KR','Noto Sans KR',sans-serif !important; color:#000;}
/*header*/
.header{ box-shadow: 0px 0px 15px #00000029;     position: relative; z-index: 10; position: fixed; width: 100%; background: #fff;}
header>.header_cover{max-width: 1240px; width:100%; height:80px; margin:0 auto; display: flex; align-items: center; justify-content: space-between;}
header ul:after{clear:both; display:block; content:"";}
header ul li{float:left;}
header ul li>a{display: block; text-align:center; font-size: 18px;     padding: 0px 25px; height: 80px; line-height: 80px;}
header ul li>a:hover{color:#0047EE;}
header ul li>a.header_on{color:#0047EE;}
header .login_box:after{clear:both; display:block; content:"";}
header .login_box>a:nth-child(1){font-size: 14px; color:#0047EE; border-radius: 10px; display: block; height: 50px; line-height:50px; border:1px solid #0047EE; float:left; padding:0 20px; margin-right:15px;}
header .login_box>a:nth-child(2){font-size: 14px; color:#fff; background:#0047EE; border-radius: 10px; display: block; height: 50px; line-height:50px; border:1px solid #0047EE; float:left; padding:0 20px;}
header .login_box>a:nth-child(1):hover{background: #f3f3f3;}
header .login_box>a:nth-child(2):hover{background: #0035b3;}
header>.header_cover .login_box{position:relative;}
header .login_box #login_mypage{position:absolute; width: 180px; top: 55px; right: 15px; padding: 10px 0px; border: 1px solid #C4C4C4; border-radius: 10px; background: #fff; box-shadow: 0 0 15px #00000029; display:none;}
header .login_box #login_mypage li{float:none;}
header .login_box #login_mypage li a{height:34px; line-height:34px; font-size:15px; padding:0 10px; text-align:left;}
header .login_box #login_mypage li a:hover{background:#ECEEF9;}
.content_point{height:80px;}

/*footer*/
footer{background: #fafafa;}
footer>.footer_box01{background: #3F458F; position: relative;}
footer>.footer_box01>.bg_img{ max-width: 1240px; margin: 0 auto;}
footer>.footer_box01>.bg_img>img{position: absolute;}
footer>.footer_box01>.bg_img>img:nth-child(1){bottom: 0;}
footer>.footer_box01>.bg_img>img:nth-child(2){bottom: 0; left: calc(50% - 170px);}
footer>.footer_box01>.bg_img>img:nth-child(3){top:0; left: calc(75% - 200px);}
footer>.footer_box01 .cont01{width:fit-content; margin:0 auto; padding-top:50px; position: relative; z-index: 5;}
footer>.footer_box01 .cont01:after{clear:both; display:block; content:"";}
footer>.footer_box01 .cont01>div{float:left;}
footer>.footer_box01 .cont01>div.text_box{margin-left:50px; padding-top:10px;}
footer>.footer_box01 .cont01>div.text_box h4{color:#fff; font-size:35px; margin-bottom:25px;}
footer>.footer_box01 .cont01>div.text_box p{color:#fff; font-size:17px; font-weight: 300; margin-bottom:25px;}
footer>.footer_box01 .cont01>div.text_box a{display: block; border:1px solid #fff; border-radius: 10px; width: fit-content; padding: 5px 30px;}

footer>.footer_box02{max-width: 1240px; margin: 0 auto; padding-top:40px; padding-bottom: 30px;}
footer>.footer_box02:after{clear:both; display:block; content:"";}
footer>.footer_box02>div{float:left;}
footer>.footer_box02 .cont01{margin-right: 70px;}
footer>.footer_box02 .cont02 ul{margin-bottom:15px;}
footer>.footer_box02 .cont02 ul:after{clear:both; display:block; content:"";}
footer>.footer_box02 .cont02 ul>li{float: left; margin-right:10px;}
footer>.footer_box02 .cont02 ul>li>a{font-size: 16px; color:#707070;}
footer>.footer_box02 .cont02 ul>li>a:hover{color: #000; font-weight:bold;}
footer>.footer_box02 .cont02 p{font-size: 15px; color: #707070;}
footer>.footer_box02 .cont02 p>span{display: inline-block; margin: -2px 7px; border-right: 2px solid #707070; height: 15px;}

footer>.footer_box03 p{color:#707070; font-size:14px; text-align: center; padding:15px 0px; background: #EBEBEB;}

/*폰트*/

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: normal;
  src: url("../../font/NotoSansKR-Regular.woff2") format('woff2'),
  url("../../font/NotoSansKR-Regular.woff") format('woff'),
  url("../../font/NotoSansKR-Regular.otf") format('truetype')
}


@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 500;
  src: url("../../font/NotoSansKR-Medium.woff2") format('woff2'),
  url("../../font/NotoSansKR-Medium.woff") format('woff'),
  url("../../font/NotoSansKR-Medium.otf") format('truetype')
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: bold;
  src: url("../../font/NotoSansKR-Bold.woff2") format('woff2'),
  url("../../font/NotoSansKR-Bold.woff") format('woff'),
  url("../../font/NotoSansKR-Bold.otf") format('truetype')
}

@media (max-width:1240px){
  header>.header_cover{padding:0 10px;}

  footer>.footer_box02{padding-left:10px;}
}

@media (max-width:1140px){
  header ul li>a{padding: 0px 15px;}
}
@media (max-width:1000px){
  .content_point{height:130px;}
  header>.header_cover{padding:0px;}
  header>.header_cover:after{display: block; content:""; clear:both;}
  header>.header_cover{display: block; height:130px;}
  header>.header_cover>a{display: block; height:80px; line-height: 80px; text-align: center; border-bottom:1px solid #ddd;}
  header>.header_cover>a>img{display: inline !important; position: relative; top: 15px;}
  header>.header_cover>ul{width: fit-content; margin: 0 auto;}
  header>.header_cover>ul a{height: 50px; line-height: 50px;}
  header>.header_cover .login_box{    position: absolute; right: 10px; top: 15px;}

  footer>.footer_box01>.bg_img{display:none;}
  footer>.footer_box01 .cont01>div.img_box{width: calc(100% - 430px);}
  footer>.footer_box02{padding-left: 0px;}
  footer>.footer_box02>div{float:none;}
  footer>.footer_box02 .cont01{margin-right: 0px; text-align: center; margin-bottom:20px;}
  footer>.footer_box02 .cont01>img{display: inline !important;}
  footer>.footer_box02 .cont02{text-align: center;}
  footer>.footer_box02 .cont02 ul{width: fit-content; margin: 0 auto; margin-bottom:15px;}
}
@media (max-width:700px){
  footer>.footer_box01 .cont01>div.img_box{display:none;}
  footer>.footer_box01 .cont01>div.text_box{margin-left: 0; float: none; padding-top: 0px; padding-bottom: 50px;}
}
@media (max-width:600px){
  header>.header_cover .login_box{top:5px;}
  header>.header_cover>a{line-height: 100px; height:100px;}
  header>.header_cover>a>img{top:30px;}
  header>.header_cover{height: 140px;}
  header .login_box>a:nth-child(1){ height: 30px; line-height: 28px; margin-right:10px;}
  header .login_box>a:nth-child(2){ height: 30px; line-height: 28px; }
}
@media (max-width:550px){
  header>.header_cover{height:180px;}
  header ul li>a{font-size: 16px;  padding: 0px 10px;}
  header>.header_cover>a{border-bottom:none;}
  header>.header_cover>ul a{height:40px; line-height: 40px; border: 1px solid #c4c4c4; border-bottom: none; border-right: none;}
  header>.header_cover>ul li:nth-child(3n + 1) a{border-left:none;}
  header>.header_cover>ul li:nth-child(n + 4) a{border-bottom:1px solid #c4c4c4;}
  header>.header_cover>ul{display: flex; flex-wrap: wrap; width:100%;}
  header ul li{width:33.3%}
  
  .content_point{height:180px;}
  
}
@media (max-width:500px){
  header .login_box>a{padding:0 10px !important;}
  footer>.footer_box02{padding-top:30px; padding-bottom: 20px;}
  footer>.footer_box02 .cont02 p{line-height: 30px;}
  footer>.footer_box02 .cont02 p>span{display:block; height:0px;}
}
@media (max-width:400px){
  footer>.footer_box01{padding:0 10px;}
  footer>.footer_box01 .cont01>div.text_box h4{font-size:28px;}
  footer>.footer_box01 .cont01>div.text_box p br{display:none;}
  header ul li>a{padding: 0px 5px;}
}
@media (max-width:350px){
  header ul li>a{padding: 0px 2px; font-size: 15px;}
}
@media (max-width:300px){
  header ul li>a{padding: 0px 3px; font-size: 13px;}
}